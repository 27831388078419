// Init
import React from 'react';
import Gif from '../../assets/gif/falconLoaderWhite.gif';
// import { RotateLoader } from 'react-spinners';
// import Gif from '../../assets/gif/falconLoaderFull.gif';

export default function FalconLoader() {
  return (
    <div className="fallbackScreen">
      <img src={Gif} alt="GIF" loop />
      {/* <RotateLoader size={30} color="#ffffff" /> */}
    </div>
  );
}

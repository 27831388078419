// Init
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min.js';
import FalconLoader from '../components/Common/FalconLoader.js';
const Home = lazy(() => import('../containers/Home.js'));
const About = lazy(() => import('../containers/About.js'));
const ContactUs = lazy(() => import('../containers/ContactUs.js'));
const Blog = lazy(() => import('../containers/Blog'));
const RemoteTeam = lazy(() => import('../containers/Blog/RemoteTeam'));
const BusinessWebsite = lazy(() => import('../containers/Blog/BusinessWebsite'));
const LegalMentions = lazy(() => import('../containers/LegalMentions'));
const OurProjects = lazy(() => import('../containers/OurProjects'));
const Clients = lazy(() => import('../containers/Clients'));
const WebDev = lazy(() => import('../containers/Services/WebDev.js'));
const AppDev = lazy(() => import('../containers/Services/AppDev.js'));
const SoftwareDev = lazy(() => import('../containers/Services/SoftwareDev.js'));
const OnDemandDev = lazy(() => import('../containers/Services/OnDemandDev.js'));
const UiDev = lazy(() => import('../containers/Services/UiDev.js'));
const Permispop = lazy(() => import('../containers/Project/Permispop'));
const Quadrabase = lazy(() => import('../containers/Project/Quadrabase'));
const Kidcab = lazy(() => import('../containers/Project/Kidcab'));
const DesignConfection = lazy(() => import('../containers/Project/DesignConfection'));
const EZbox = lazy(() => import('../containers/Project/EZbox'));
const Telehealth = lazy(() => import('../containers/Project/Telehealth'));
const RealEstate = lazy(() => import('../containers/Project/RealEstate'));
const Orgaplan = lazy(() => import('../containers/Project/Orgaplan'));
const Tilt = lazy(() => import('../containers/Project/Tilt'));
const Excellence = lazy(() => import('../containers/Project/Excellence'));
const Table = lazy(() => import('../containers/Project/Table'));
const Sindoor = lazy(() => import('../containers/Project/Sindoor'));
const Zypzap = lazy(() => import('../containers/Project/Zypzap'));
const Yuwway = lazy(() => import('../containers/Project/Yuwway'));
const FacilityManagement = lazy(() => import('../containers/Project/FacilityManagement'));
const Fireman = lazy(() => import('../containers/Project/Fireman'));
const Wina = lazy(() => import('../containers/Project/Wina'));
const Orientator = lazy(() => import('../containers/Project/Orientator'));
const RegisterRH = lazy(() => import('../containers/Project/RegisterRH'));
const Policy = lazy(() => import('../containers/Policy/Policy.js'));
const AgileMethod = lazy(() => import('../containers/Blog/AgileMethod'));
const SuccessfulBusiness = lazy(() => import('../containers/Blog/SuccessfulBusiness'));
const AdPage = lazy(() => import('../containers/AdPage.js'));
const AdPage1 = lazy(() => import('../containers/AdPage1.js'));
const ErrorPage = lazy(() => import('../containers/ErrorPage.js'));
const MeduSched = lazy(() => import('../containers/Project/MeduSched'));
const AquariumServicePro = lazy(() => import('../containers/Project/AquariumServicePro'));
const Repisodic = lazy(() => import('../containers/Project/Repisodic.js'));
const BlackIce = lazy(() => import('../containers/Project/BlackIce.js'));
const SoundWavePoster = lazy(() => import('../containers/Project/SoundWavePoster.js'));
const PostExo = lazy(() => import('../containers/Project/PostExo.js'));
const ResetMe = lazy(() => import('../containers/Project/ResetMe.js'));
const SalesPro = lazy(() => import('../containers/Project/SalesPro.js'));
const ShopMuslimW = lazy(() => import('../containers/Project/ShopMuslimW.js'));
const Ameen = lazy(() => import('../containers/Project/Ameen.js'));

const renderLoader = () => <FalconLoader />;
const index = () => {
  return (
    <Suspense fallback={renderLoader()}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/services/web-development" component={WebDev} />
        <Route exact path="/services/app-development" component={AppDev} />
        <Route exact path="/services/software-development" component={SoftwareDev} />
        <Route exact path="/services/ondemand-developers" component={OnDemandDev} />
        <Route exact path="/services/ui/ux-development" component={UiDev} />
        <Route exact path="/contactus" component={ContactUs} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/blog/remoteteam" component={RemoteTeam} />
        <Route exact path="/blog/businesswebsite" component={BusinessWebsite} />
        <Route exact path="/blog/agilemethodology" component={AgileMethod} />
        <Route exact path="/blog/successfulbusiness" component={SuccessfulBusiness} />
        <Route exact path="/legalmentions" component={LegalMentions} />
        <Route exact path="/policy" component={Policy} />
        <Route exact path="/ourprojects" component={OurProjects} />
        <Route exact path="/clients" component={Clients} />
        <Route exact path="/ourprojects/permispop" component={Permispop} />
        <Route exact path="/ourprojects/quadrabase" component={Quadrabase} />
        <Route exact path="/ourprojects/kidcab" component={Kidcab} />
        <Route exact path="/ourprojects/designconfection" component={DesignConfection} />
        <Route exact path="/ourprojects/ezbox" component={EZbox} />
        <Route exact path="/ourprojects/telehealthplatform" component={Telehealth} />
        <Route exact path="/ourprojects/realestate" component={RealEstate} />
        <Route exact path="/ourprojects/orgaplan" component={Orgaplan} />
        <Route exact path="/ourprojects/tilt" component={Tilt} />
        <Route exact path="/ourprojects/excellence" component={Excellence} />
        <Route exact path="/ourprojects/table" component={Table} />
        <Route exact path="/ourprojects/sindoor" component={Sindoor} />
        <Route exact path="/ourprojects/zypzap" component={Zypzap} />
        <Route exact path="/ourprojects/yuwway" component={Yuwway} />
        <Route exact path="/ourprojects/facilitymanagement" component={FacilityManagement} />
        <Route exact path="/ourprojects/fireman" component={Fireman} />
        <Route exact path="/ourprojects/wina" component={Wina} />
        <Route exact path="/ourprojects/orientator" component={Orientator} />
        <Route exact path="/ourprojects/registerrh" component={RegisterRH} />
        <Route exact path="/yourdevelopmentteam" component={AdPage} />
        <Route exact path="/youravengerteam" component={AdPage1} />
        <Route exact path="/ourprojects/MeduSched" component={MeduSched} />
        <Route exact path="/ourprojects/AquariumServicePro" component={AquariumServicePro} />
        <Route exact path="/ourprojects/Repisodic" component={Repisodic} />
        <Route exact path="/ourprojects/BlackIce" component={BlackIce} />
        <Route exact path="/ourprojects/SoundWavePoster" component={SoundWavePoster} />
        <Route exact path="/ourprojects/PostExo" component={PostExo} />
        <Route exact path="/ourprojects/resetme" component={ResetMe} />
        <Route exact path="/ourprojects/salespro" component={SalesPro} />
        <Route exact path="/ourprojects/shopmuslimway" component={ShopMuslimW} />
        <Route exact path="/ourprojects/ameen" component={Ameen} />
        {/* <Route exact path="/ourprojects/fastestdelivery" component={FastDelivery} /> */}
        <Route exact path="/pagenotfound" component={ErrorPage} />
        <Redirect from="*" to="/pagenotfound" />
      </Switch>
    </Suspense>
  );
};

export default index;

// imports

// Routes
// import Home from '../containers/Home.js';
// import About from '../containers/About.js';
// import ContactUs from '../containers/ContactUs.js';
// import Blog from '../containers/Blog';
// import RemoteTeam from '../containers/Blog/RemoteTeam';
// import BusinessWebsite from '../containers/Blog/BusinessWebsite';
// import LegalMentions from '../containers/LegalMentions';
// import OurProjects from '../containers/OurProjects';
// import Clients from '../containers/Clients';
// import WebDev from '../containers/Services/WebDev.js';
// import AppDev from '../containers/Services/AppDev.js';
// import SoftwareDev from '../containers/Services/SoftwareDev.js';
// import OnDemandDev from '../containers/Services/OnDemandDev.js';
// import UiDev from '../containers/Services/UiDev.js';
// import Permispop from '../containers/Project/Permispop';
// import Quadrabase from '../containers/Project/Quadrabase';
// import Kidcab from '../containers/Project/Kidcab';
// import DesignConfection from '../containers/Project/DesignConfection';
// import EZbox from '../containers/Project/EZbox';
// import Telehealth from '../containers/Project/Telehealth';
// import RealEstate from '../containers/Project/RealEstate';
// import Orgaplan from '../containers/Project/Orgaplan';
// import Tilt from '../containers/Project/Tilt';
// import Excellence from '../containers/Project/Excellence';
// import Table from '../containers/Project/Table';
// import Sindoor from '../containers/Project/Sindoor';
// import Zypzap from '../containers/Project/Zypzap';
// import Yuwway from '../containers/Project/Yuwway';
// import FacilityManagement from '../containers/Project/FacilityManagement';
// import Fireman from '../containers/Project/Fireman';
// import Wina from '../containers/Project/Wina';
// import Orientator from '../containers/Project/Orientator';
// import RegisterRH from '../containers/Project/RegisterRH';
// import Policy from '../containers/Policy/Policy.js';
// import AgileMethod from '../containers/Blog/AgileMethod';
// import SuccessfulBusiness from '../containers/Blog/SuccessfulBusiness';
// import AdPage from '../containers/AdPage.js';
// import AdPage1 from '../containers/AdPage1.js';
// import ErrorPage from '../containers/ErrorPage.js';
// import MeduSched from '../containers/Project/MeduSched';
// import AquariumServicePro from '../containers/Project/AquariumServicePro';
// import Repisodic from '../containers/Project/Repisodic.js';
// import BlackIce from '../containers/Project/BlackIce.js';
// import SoundWavePoster from '../containers/Project/SoundWavePoster.js';
// import PostExo from '../containers/Project/PostExo.js';
// import ResetMe from '../containers/Project/ResetMe.js';
// import SalesPro from '../containers/Project/SalesPro.js';
// import ShopMuslimW from '../containers/Project/ShopMuslimW.js';
// import Ameen from '../containers/Project/Ameen.js';
// import FastDelivery from '../containers/Project/FastDelivery.js';
